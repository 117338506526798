<template>
    <!-- 招生管理-招生管理 -->
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <!-- 头部面包屑 -->
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">招生管理</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">招生管理</a>
                </span>
            </div>
            <!-- 主体区域 -->
            <div class="framePage-body">
                <!-- 搜索区域 -->
                <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
                    <div class="searchbox">
                        <div title="机构名称" class="searchboxItem">
                            <span class="itemLabel">机构名称:</span>
                            <el-input v-model="searchForm.compName" placeholder="请输入机构名称" type="text" clearable size="small"/>
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="getListData(1)">查询</el-button>
                            <el-button type="primary" class="bgc-bv" round @click="newlyAdded">新增</el-button>
                        </div>
                    </div>
                </div>

                <!-- 列表区域 -->
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="listData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                            stripe
                        >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                                :index="indexMethod"
                                width="50"
                            />
                            <el-table-column
                                label="类型"
                            >
                                <template v-slot="{row}">
                                    {{$setDictionary('CT_CHANNEL_TYPE', row.channelType)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="机构名称"
                                prop="compName"
                                min-width="150px"
                            />
                            <el-table-column
                                label="联系人"
                                prop="contactUser"
                                min-width="100px"
                            />
                            <el-table-column
                                label="联系方式"
                                prop="contactPhone"
                                min-width="100px"
                            />
                            <el-table-column
                                label="已招生人数"
                                prop="peopleNum"
                            />
                            <el-table-column
                                label="账号名称"
                                prop="adminName"
                                min-width="150px"
                            />
                            <el-table-column
                                label="二维码名称"
                                prop="qrCodeName"
                                min-width="100px"
                            />
                            <el-table-column
                                label="备注"
                                prop="bz"
                                min-width="150px"
                            />
                          <el-table-column label="是否启用" align="center" min-width="100">
                            <div slot-scope="scope" class="table-switch">
                              <el-switch
                                  :width="20"
                                  v-model="scope.row.state"
                                  active-value="10"
                                  inactive-value="20"
                                  active-color="#13ce66"
                                  @change="
                      (val) => {
                        handleStu(val, scope.row.adminId);
                      }
                    "
                              ></el-switch>
                              <span>{{ scope.row.state == 10 ? "启用" : "停用" }}</span>
                            </div>
                          </el-table-column>
                            <el-table-column
                                label="操作"
                                align="center"
                                width="300px"
                                fixed="right"
                            >
                                <template v-slot="{row}">
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="restPassword(row.channelId)">重置密码</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="edit(row)">编辑</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="enrollment(row)">查看招生情况</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="getQrCode(row)">招生二维码</el-button>
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>

                <!-- 分页器 -->
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
            </div>
        </div>

        <!-- 新增对话框 -->
        <el-dialog
            :title="dialogType===1?'新增渠道':'编辑渠道'"
            :visible.sync="dialogVisible"
            width="800px"
            @closed="dialogClosed"
        >
            <el-form ref="dialogForm" :rules="rules" :model="dialogForm" size="small" label-width="100px">
                <el-form-item label="类型" prop="channelType">
                    <el-select clearable placeholder="请选择类型" @focus="clearValid('channelType')" v-model="dialogForm.channelType">
                        <el-option
                            v-for="item in channelTypeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="机构名称" v-if="dialogForm.channelType==='20'">
                    <el-input @focus="clearValid('compName')" v-model="dialogForm.compName" placeholder="请输入机构名称"></el-input>
                </el-form-item>
                <el-form-item label="登录账号" prop="adminName">
                    <el-input @focus="clearValid('adminName')" v-model="dialogForm.adminName" placeholder="请输入登录账号"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="contactUser">
                    <el-input @focus="clearValid('contactUser')" v-model="dialogForm.contactUser" placeholder="请输入联系人"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="contactPhone">
                    <el-input @focus="clearValid('contactPhone')" v-model="dialogForm.contactPhone" @input="filter('contactPhone')" placeholder="请输入联系方式"></el-input>
                </el-form-item>
                <el-form-item label="二维码名称" prop="qrCodeName">
                    <el-input @focus="clearValid('qrCodeName')" v-model="dialogForm.qrCodeName" placeholder="请输入二维码名称"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input type="textarea" :rows="6" resize="none" maxlength="200" show-word-limit v-model="dialogForm.bz" placeholder="请输入备注"></el-input>
                </el-form-item>
                <el-form-item label="数据权限">
                    <el-checkbox v-model="dialogForm.mobileSensitive" label="手机号脱敏"></el-checkbox>
                    <el-checkbox v-model="dialogForm.adminSensitive" label="身份证号脱敏"></el-checkbox>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="customBtn" round @click="dialogVisible = false">取 消</el-button>
                <el-button class="customBtn" round type="primary" @click="submit">保 存</el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="招生二维码"
            :visible.sync="codeDialogVisible"
            class="code"
            width="400px">
            <div class="codeimg-wrap">
                <img class="codeimg" :src="codeSrc.viewURL" alt="">
                <el-button type="primary" style="margin-top:10px;" @click="downloadCode(codeSrc)" size="small">下载二维码</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import Empty from "@/components/Empty.vue";
    import PageNum from "@/components/PageNum.vue";
    import List from "@/mixins/List";
    import { resetKeepAlive } from "@/utils/common";
    export default {
        name:"channelManagement",
        components:{
            Empty,
            PageNum
        },
        mixins:[List],
        data(){
            return{
                // 搜索区域表单
                searchForm:{
                    compName:"",//机构名称id
                },
                //培训列表数据
                listData:[],
                // 分页器数据
                pagination:{
                    total: 0,//总条数
                    size: 10,//每页几条数据
                    current: 1//当前页
                },
                // 对话框表单信息
                dialogForm:{
                    channelType:"",//类型
                    compName:"",//机构名称
                    adminName:"",//登录账号
                    contactUser:"",//联系人
                    contactPhone:"",//联系方式
                    adminSensitive:false,//身份证号是否脱敏
                    mobileSensitive:false,// 手机号是否脱敏
                    qrCodeName:"",//二维码名称
                    bz:"",//备注
                },
                // 培训类型下拉列表数据
                channelTypeList:[],
                //对话框可见性
                dialogVisible:false,
                // 招生二维码链接
                codeSrc:{},
                // 招生二维码对话框可见性
                codeDialogVisible:false,
                // 表单校验规则
                rules:{
                    channelType:[
                        {required:true,message:"请选择渠道类型",trigger:"change"}
                    ],
                    compName:[
                        {required:true,message:"请输入机构名称",trigger:"blur"}
                    ],
                    adminName:[
                        {required:true,message:"请输入登录账号",trigger:"blur"}
                    ],
                    contactUser:[
                        {required:true,message:"请输入联系人",trigger:"blur"}
                    ],
                    contactPhone:[
                        {required:true,message:"请输入联系方式",trigger:"blur"}
                    ],
                    qrCodeName:[
                        {required:true,message:"请输入二维码名称",trigger:"blur"}
                    ],
                },
                // 对话框类型
                dialogType:1,// 1-新增 2-编辑
            }
        },
        methods:{
          // 序号
          indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
          },
            // 初始化数据
            initData(){
                this.getListData();
                this.dictionary();
            },
            // 获取列表数据
            getListData(current){
                const {searchForm,pagination}=this;
                if(current) this.pagination.current=current;
                this.$post("/biz/ct/channel/pageListChannel",{
                    pageNum: pagination.current,
                    pageSize: pagination.size,
                    ...searchForm
                }).then(res=>{
                    this.listData=res?.data?.list||[];
                    pagination.total=res?.data?.total||0;
                }).catch(e=>{
                    console.error("获取列表数据出错",e);
                });
            },
            // 新增
            newlyAdded(){
                this.dialogType=1;
                this.dialogVisible=true;
            },
            // 编辑
            edit(row){
                this.dialogType=2;
                const {channelId}=row;
                this.$post("/biz/ct/channel/getById",{
                    channelId
                }).then(res=>{
                    // 回显赋值
                    this.dialogForm=res.data||{};
                    this.dialogVisible=true;
                }).catch(e=>{
                    console.error("编辑回显出错",e);
                });
            },
            // 提交
            submit(){
                const {dialogForm,dialogType}=this;
                this.$refs.dialogForm.validate(valid=>{
                    if(valid){
                        if(dialogType===1){
                            // 新增
                            this.$post("/biz/ct/channel/saveChannel",dialogForm).then(()=>{
                                this.getListData();
                                this.$message.success("添加成功");
                                this.dialogVisible=false;
                            }).catch(e=>{
                                console.error("新增渠道出错",e);
                            });
                        }else{
                            // 编辑
                            this.$post("/biz/ct/channel/update",dialogForm).then(()=>{
                                this.getListData();
                                this.$message.success("修改成功");
                                this.dialogVisible=false;
                            }).catch(e=>{
                                console.error("修改渠道出错",e);
                            });
                        }
                    }
                });
            },
            // 查看招生详情
            enrollment(row){
                const {channelId}=row;
                this.$router.push({
                    path:'/web/institutionalManagement/channelManagement/checkAdmissions',
                    query:{
                        channelId
                    }
                });
            },
            restPassword(channelId){
              this.$confirm('是否将用户账号的密码重置？<br> 重置后，变为初始密码Aa+手机号后六位', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true
              }).then(() => {
                this.$post("/biz/ct/channel/restPassword", {
                  channelId,
                })
                  .then((ret) => {
                    if (ret.status == "0") {
                      this.$message({
                        message: ret.message,
                        type: 'success'
                      })
                      this.getData(-1);
                    }
                  })
                  .catch((err) => {
                  });
              }).catch(() => { });
            },
            // 招生二维码
            getQrCode(row){
                const {channelId,qrCodeName}=row;
                this.codeSrc={};
                this.$post("/biz/ct/channel/getQrCode",{
                    channelId
                }).then(res=>{
                    this.codeSrc=res.data||{};
                    this.codeDialogVisible=true;

                }).catch(e=>{
                    console.error("获取招生二维码出错",e);
                });
            },
            // 下载二维码
            downloadCode(src){
                window.open(src.downloadURL);
            },
            // 过滤非法数据
            filter(key){
                const {dialogForm}=this;
                dialogForm[key]=dialogForm[key].replace(/\D/g,"");
            },
            // 当前页改变
            currentChange(current){
                this.pagination.current=current;
                this.getListData();
            },
            // 每页条数改变
            sizeChange(size){
                this.pagination.size=size;
                this.getListData();
            },
            // 清除表单某一项的校验结果
            clearValid(key){
                this.$refs.dialogForm.clearValidate(key);
            },
            // 对话框关闭清除表单校验结果
            dialogClosed(){
                this.$refs.dialogForm.resetFields();
                this.dialogForm={
                    channelType:"",//类型
                    compName:"",//机构名称
                    adminName:"",//登录账号
                    contactUser:"",//联系人
                    contactPhone:"",//联系方式
                    adminSensitive:false,//身份证号是否脱敏
                    mobileSensitive:false,// 手机号是否脱敏
                    qrCodeName:"",//二维码名称
                    bz:"",//备注
                }
            },
            // 处理类型字典数据
            dictionary(){
                const {channelTypeList}=this;
                const obj=this.$setDictionary('CT_CHANNEL_TYPE', "list");
                Object.keys(obj).forEach(key=>{
                    channelTypeList.push({
                        label:obj[key],
                        value:key
                    });
                });;
            },
            // 设置表格高度
            getTableHeight(opDom = false, page = true) {
                let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
                if (opDom)  tHeight -= 40 + 0.675 * 16 + 1;
                if (page)  tHeight -= 32;
                this.tableHeight = tHeight;
            },
          /**
           * 修改状态
           */
          handleStu(state, adminId) {
            this.$post("/sys/admin/modifyStatCt", {
              adminId,
              state,
            }).then((res) => {
              if (res.status == "0") {
                this.$message({
                  type: "success",
                  message: "修改成功",
                });
                this.getData(-1);
              }
            });
          },
        },
        watch:{
            $route(to,from){
                if(from.path==="/web/institutionalManagement/channelManagement/checkAdmissions"){
                    this.getListData();
                }
            }
        },
        created(){
            this.initData();
        },
        beforeRouteLeave: resetKeepAlive,
    }
</script>

<style lang="less" scoped>
    .templateList{
        .customBtn:nth-child(2){
            background-color: #5C6BE8;
            border: none;
            &:hover{
                background-color: #6875ee;
            }
        }
        .code{
            .codeimg-wrap{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .codeimg{
                    display: block;
                    margin: 0 auto;
                }
                .qrCodeName{
                    margin-bottom: 20px;
                }
                .el-button{
                    margin: 0 auto;
                    border: none;
                    background-color: #5C6BE8;
                    &:hover{
                        background-color: #6875ee;
                    }
                }
            }
        }
    }
</style>
